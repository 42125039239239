<template>
  <div class="step">
    <van-steps
      :active="active"
      active-icon="checked"
      active-color="#38f"
      :style="{ width: steps }"
      v-if="levels"
    >
      <van-step>提交审批</van-step>
      <van-step v-for="index of hierarchy" :key="index"
        >{{ index }}级审批</van-step
      >
      <van-step v-if="!showZb">审批完成</van-step>
      <van-step v-else>总部审批</van-step>
    </van-steps>
  </div>
</template>
<script>
export default {
  props: {
    levels: {
      type: Number,
    },
    hierarchy: {
      type: Number,
    },
    showZb: {
      type: String,
    },
  },
  data() {
    return {
      active: null,
    };
  },
  computed: {
    steps() {
      if (this.hierarchy == 4) {
        return "130%";
      } else if (this.hierarchy > 4) {
        return "160%";
      } else {
        return "100%";
      }
    },
  },
  created() {
    this.active = this.levels;
  },
};
</script>
<style lang="less">
.step {
  height: 70px;
  background: #fff;
  overflow-x: auto;
  margin-bottom: 10px;
  border-top: 1px solid rgb(238, 234, 234);
  border-bottom: 1px solid rgb(238, 234, 234);
}
</style>
