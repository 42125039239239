import axios from "axios";
import Vue from "vue";
import { Toast } from "vant";
import router from "../router";
import store from "../store";
import qs from "qs";
import { Loading } from "vant";

Vue.use(Toast);
Vue.use(Loading);

axios.defaults.timeout = 180 * 1000;
// 配置cookie
// axios.defaults.withCredentials = true
// 配置请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// const baseURL = process.env.NODE_ENV === 'production' ? config.BASEURL : ''
// 配置接口地址
// axios.defaults.baseURL = baseURL

// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    if (store.state.token) {
      config.headers["Authorization"] = store.state.token;
    } else {
      // config.headers["Authorization"] = "ouyangqg.yssh" // 二阶段
      // config.headers["Authorization"] = "jinlei02.yssh" // 监造
      // config.headers["Authorization"] = "yinhw.yssh" // 监造
      // config.headers["Authorization"] = "yangd255" // 监造
      // config.headers["Authorization"] = "liyongjian.yssh" // 招标文件确认、供应商分项报价
      config.headers["Authorization"] = "tianb9524" // 二阶段

      // config.headers["Authorization"] = "zhanghm53" // 招标委托单
      // config.headers["Authorization"] = "rongysh3" // 招标委托单
      // config.headers["Authorization"] = "bali.yssh" // 招标委托单
      // config.headers["Authorization"] = "chengsh227"
      // config.headers["Authorization"] = "wzmiaom" // 框架协议变更行项目审批
      // config.headers["Authorization"] = "chensj.yssh" // 化工品自采、合同评分、招标委托单
      
    }
    config.params = {
      t: new Date().getTime(),
      // loginNameA: 'wuw.cqsy',
      ...config.params,
    };
    return config;
  },
  (err) => {
    // Message.error('错误的传参')
    return Promise.reject(err);
  }
);
// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    if (res.data.code === 200) {
      if (res.data.total) {
        res.data.data = {
          data: res.data.data,
          total: res.data.total,
        };
      }
      return res.data.data;
    } else if (res.data.code === 400) {
      return Promise.reject(res);
    } else if (res.config.responseType === 'blob') {

      return res.data
    } else {
      let query = {
        data: res.data,
      };
      router
        .replace({
          name: "error",
          query,
        })
        .catch(() => { });
    }
  },
  (err) => {
    let query = {
      data: err.message,
    };
    router
      .replace({
        name: "error",
        query,
      })
      .catch(() => { });

  }
);
// GET 请求方式
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  }).catch(() => { });
}
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(params), {})
      .then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  }).catch(() => { });
}
export function gpost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, {})
      .then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  }).catch(() => { });
}
export function postdonw(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        params: params,
        responseType: "blob",
        timeout: 1000 * 60 * 10,
      })
      .then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  }).catch(() => { });
}
export function download(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        responseType: "blob",
        timeout: 1000 * 60 * 10,
      })
      .then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  }).catch(() => { });
}
