<template>
  <div class="step">
    <van-steps
      :active="active"
      active-icon="checked"
      active-color="#38f"
      :style="{ width: steps }"
    >
      <van-step v-for="(item, index) in List" :key="index">{{
        item.taskName
      }}</van-step>
    </van-steps>
  </div>
</template>
<script>
export default {
  props: {
    List: {
      required: true,
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      // active: 1,
      // steps: '300%'
    };
  },
  computed: {
    steps() {
      return this.List.length * 75 + '%'
    },
    active() {
      let i = 0
      this.List.forEach((item, index) => {
        if (item.toDoFlag == 1) {
          i = index
        }
      })
      return i
    }
  },
  created() {

  },
  mounted() { },
  methods: {}
}
</script>
<style lang="less">
.step {
  height: 70px;
  background: #fff;
  overflow-x: auto;
  margin-bottom: 10px;
  border-top: 1px solid rgb(238, 234, 234);
  border-bottom: 1px solid rgb(238, 234, 234);
}
</style>