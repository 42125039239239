import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    uesrMessage: {},
    token: "",
    gsnBreachApply: [],
    gsnExpiredCertificate: [],
    gsnProductsApply: [],
    gsnProductsChangeApply: [],
    gsnProductsChangeConfirm: [],
    gsnProductsConfirm: [],
    gsnRiskApply: [],
    gsnSupplierApply: [],
    gsnSupplierConfirm: [],
  },
  mutations: {
    setUesrMessage(state, params) {
      state.uesrMessage = params;
    },
    setToken(state, params) {
      state.token = params;
    },
    setGsnBreachApply(state, params) {
      if (state.gsnBreachApply && state.gsnBreachApply.length > 0) {
        state.gsnBreachApply = state.gsnBreachApply.filter((item) => {
          return item != params;
        });
      }
      if (state.gsnBreachApply.length >= 10) {
        state.gsnBreachApply.pop();
      }
      state.gsnBreachApply.unshift(params);
    },
    setGsnExpiredCertificate(state, params) {
      if (
        state.gsnExpiredCertificate &&
        state.gsnExpiredCertificate.length > 0
      ) {
        state.gsnExpiredCertificate = state.gsnExpiredCertificate.filter(
          (item) => {
            return item != params;
          }
        );
      }
      if (state.gsnExpiredCertificate.length >= 10) {
        state.gsnExpiredCertificate.pop();
      }
      state.gsnExpiredCertificate.unshift(params);
    },
    setGsnProductsApply(state, params) {
      if (state.gsnProductsApply && state.gsnProductsApply.length > 0) {
        state.gsnProductsApply = state.gsnProductsApply.filter((item) => {
          return item != params;
        });
      }
      if (state.gsnProductsApply.length >= 10) {
        state.gsnProductsApply.pop();
      }
      state.gsnProductsApply.unshift(params);
    },
    setGsnProductsChangeApply(state, params) {
      if (
        state.gsnProductsChangeApply &&
        state.gsnProductsChangeApply.length > 0
      ) {
        state.gsnProductsChangeApply = state.gsnProductsChangeApply.filter(
          (item) => {
            return item != params;
          }
        );
      }
      if (state.gsnProductsChangeApply.length >= 10) {
        state.gsnProductsChangeApply.pop();
      }
      state.gsnProductsChangeApply.unshift(params);
    },
    setGsnProductsChangeConfirm(state, params) {
      if (
        state.gsnProductsChangeConfirm &&
        state.gsnProductsChangeConfirm.length > 0
      ) {
        state.gsnProductsChangeConfirm = state.gsnProductsChangeConfirm.filter(
          (item) => {
            return item != params;
          }
        );
      }
      if (state.gsnProductsChangeConfirm.length >= 10) {
        state.gsnProductsChangeConfirm.pop();
      }
      state.gsnProductsChangeConfirm.unshift(params);
    },
    setGsnProductsConfirm(state, params) {
      if (state.gsnProductsConfirm && state.gsnProductsConfirm.length > 0) {
        state.gsnProductsConfirm = state.gsnProductsConfirm.filter((item) => {
          return item != params;
        });
      }
      if (state.gsnProductsConfirm.length >= 10) {
        state.gsnProductsConfirm.pop();
      }
      state.gsnProductsConfirm.unshift(params);
    },
    setGsnRiskApply(state, params) {
      if (state.gsnRiskApply && state.gsnRiskApply.length > 0) {
        state.gsnRiskApply = state.gsnRiskApply.filter((item) => {
          return item != params;
        });
      }
      if (state.gsnRiskApply.length >= 10) {
        state.gsnRiskApply.pop();
      }
      state.gsnRiskApply.unshift(params);
    },
    setGsnSupplierApply(state, params) {
      if (state.gsnSupplierApply && state.gsnSupplierApply.length > 0) {
        state.gsnSupplierApply = state.gsnSupplierApply.filter((item) => {
          return item != params;
        });
      }
      if (state.gsnSupplierApply.length >= 10) {
        state.gsnSupplierApply.pop();
      }
      state.gsnSupplierApply.unshift(params);
    },
    setGsnSupplierConfirm(state, params) {
      if (state.gsnSupplierConfirm && state.gsnSupplierConfirm.length > 0) {
        state.gsnSupplierConfirm = state.gsnSupplierConfirm.filter((item) => {
          return item != params;
        });
      }
      if (state.gsnSupplierConfirm.length >= 10) {
        state.gsnSupplierConfirm.pop();
      }
      state.gsnSupplierConfirm.unshift(params);
    },
    delSearch(state, name) {
      state[name] = [];
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({ key: "ecgsn-store" })],
});
