import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "ecprocurement",
  },

  {
    path: "/preview",
    name: "preview",
    component: () => import("../views/preview"),
  },
  {
    path: "/previewGsn",
    name: "previewGsn",
    component: () => import("../views/previewGsn"),
  },
  // 错误页面
  {
    path: "/error",
    name: "error",
    component: () => import("../views/error.vue"),
  },
  // 采购预案审批
  {
    path: "/ecprocurement",
    name: "ecprocurement",
    component: () => import("../views/ecprocurement"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/procurementDetails",
    name: "procurementDetails",
    component: () => import("../views/ecprocurement/procurementDetails"),
  },
  {
    path: "/procurementsearch",
    name: "procurementsearch",
    component: () => import("../views/ecprocurement/search"),
  },
  {
    path: "/noagree",
    name: "noagree",
    component: () => import("../views/noagree"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/search"),
  },
  // 采购方案审批
  {
    path: "/ecprocurementplan",
    name: "ecprocurementplan",
    component: () => import("../views/ecprocurementplan"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/procurementplanDetails",
    name: "procurementplanDetails",
    component: () =>
      import("../views/ecprocurementplan/procurementplanDetails"),
  },
  {
    path: "/procurementplansearch",
    name: "procurementplansearch",
    component: () => import("../views/ecprocurementplan/search"),
  },
  // 招标委托单审批
  {
    path: "/ectenderentrust",
    name: "ectenderentrust",
    component: () => import("../views/ectenderentrust"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/tenderentrustDetails",
    name: "tenderentrustDetails",
    component: () => import("../views/ectenderentrust/tenderentrustDetails"),
  },

  {
    path: "/goodsDetails",
    name: "goodsDetails",
    component: () => import("../views/ectenderentrust/goodsDetails"),
  },
  {
    path: "/tenderentrustsearch",
    name: "tenderentrustsearch",
    component: () => import("../views/ectenderentrust/search"),
  },
  //独家采购
  {
    path: "/ecexclusive",
    name: "ecexclusive",
    component: () => import("../views/ecexclusive"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/ecexclusiveDetails",
    name: "ecexclusiveDetails",
    component: () => import("../views/ecexclusive/ecexclusiveDetails"),
  },
  {
    path: "/exclusivestearch",
    name: "exclusivestearch",
    component: () => import("../views/ecexclusive/search"),
  },
  //重新询价审批
  {
    path: "/ecmakeinquiry",
    name: "ecmakeinquiry",
    component: () => import("../views/ecmakeinquiry"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/ecmakeinquiryDetails",
    name: "ecmakeinquiryDetails",
    component: () => import("../views/ecmakeinquiry/ecmakeinquiryDetails"),
  },
  {
    path: "/makeinquirystearch",
    name: "makeinquirytearch",
    component: () => import("../views/ecmakeinquiry/search"),
  },
  //框架协议审批
  {
    path: "/eccompany",
    name: "eccompany",
    component: () => import("../views/eccompany"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/eccompanyDetails",
    name: "eccompanyDetails",
    component: () => import("../views/eccompany/eccompanyDetails"),
  },
  {
    path: "/companysearch",
    name: "companysearch",
    component: () => import("../views/eccompany/search"),
  },
  //加入执行企业审批
  {
    path: "/ecagreement",
    name: "ecagreement",
    component: () => import("../views/ecagreement"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/ecagreementDetails",
    name: "ecagreementDetails",
    component: () => import("../views/ecagreement/ecagreementDetails"),
  },
  {
    path: "/agreementsearch",
    name: "agreementsearch",
    component: () => import("../views/ecagreement/search"),
  },
  //委托采购审批
  {
    path: "/ecentrust",
    name: "ecentrust",
    component: () => import("../views/ecentrust"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/ecentrustDetails",
    name: "ecentrustDetails",
    component: () => import("../views/ecentrust/ecentrustDetails"),
  },
  {
    path: "/entrustsearch",
    name: "entrustsearch",
    component: () => import("../views/ecentrust/search"),
  },
  //业务回退
  {
    path: "/ecbusinessback",
    name: "ecbusinessback",
    component: () => import("../views/ecbusinessback"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/ecbusinessbackDetails",
    name: "ecbusinessbackDetails",
    component: () => import("../views/ecbusinessback/ecbusinessbackDetails"),
  },
  {
    path: "/businessbacksearch",
    name: "businessbacksearch",
    component: () => import("../views/ecbusinessback/search"),
  },
  //招标变更审批
  {
    path: "/ecbtenderchange",
    name: "ecbtenderchange",
    component: () => import("../views/ecbtenderchange"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/ecbtenderchangeDetails",
    name: "ecbtenderchangeDetails",
    component: () => import("../views/ecbtenderchange/ecbtenderchangeDetails"),
  },
  {
    path: "/btenderchangesearch",
    name: "btenderchangesearch",
    component: () => import("../views/ecbtenderchange/search"),
  },
  //招标失败审批
  {
    path: "/ecbiddingfailure",
    name: "ecbiddingfailure",
    component: () => import("../views/ecbiddingfailure"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/ecbiddingfailureDetails",
    name: "ecbiddingfailureDetails",
    component: () =>
      import("../views/ecbiddingfailure/ecbiddingfailureDetails"),
  },
  {
    path: "/biddingfailuresearch",
    name: "biddingfailuresearch",
    component: () => import("../views/ecbiddingfailure/search"),
  },
  //招标终止审批
  {
    path: "/ectenderend",
    name: "ectenderend",
    component: () => import("../views/ectenderend"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/ectenderendDetails",
    name: "ectenderendDetails",
    component: () => import("../views/ectenderend/ectenderendDetails"),
  },
  {
    path: "/tenderendsearch",
    name: "tenderendsearch",
    component: () => import("../views/ectenderend/search"),
  },
  //定标结果
  {
    path: "/eccalibration",
    name: "eccalibration",
    component: () => import("../views/eccalibration"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/eccalibrationDetails",
    name: "eccalibrationDetails",
    component: () => import("../views/eccalibration/eccalibrationDetails"),
  },
  {
    path: "/eccalibrationsearch",
    name: "eccalibrationsearch",
    component: () => import("../views/eccalibration/search"),
  },
  //制造计划确认
  {
    path: "/ecManufacturConfirm",
    name: "ecManufacturConfirm",
    component: () => import("../views/ecManufacturConfirm"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/manufacturconfirmDetails",
    name: "manufacturconfirmDetails",
    component: () => import("../views/ecManufacturConfirm/manufacturconfirmDetails"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/ecManufacturConfirm/detail"),
  },
  {
    path: "/searchmanufacturcon",
    name: "searchmanufacturcon",
    component: () => import("../views/ecManufacturConfirm/search"),
  },
  //制造计划变更
  {
    path: "/ecManufacturConfirmchange",
    name: "ecManufacturConfirmchange",
    component: () => import("../views/ecManufacturConfirmchange"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/manufacturconfirmchangeDetails",
    name: "manufacturconfirmchangeDetails",
    component: () => import("../views/ecManufacturConfirmchange/manufacturconfirmchangeDetails"),

  },
  //制造方案
  {
    path: "/ecManufacturscheme",
    name: "ecManufacturscheme",
    component: () => import("../views/ecManufacturscheme"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/manufacturschemeDetails",
    name: "manufacturschemeDetails",
    component: () => import("../views/ecManufacturscheme/manufacturschemeDetails"),

  },
  {
    path: "/manufacturschemesearch",
    name: "manufacturschemesearch",
    component: () => import("../views/ecManufacturscheme/search"),

  },
  //制造计划审批
  {
    path: "/ecManufacturConfirmapproval",
    name: "ecManufacturConfirmapproval",
    component: () => import("../views/ecManufacturConfirmapproval"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/manufacturConfirmapprovalDetails",
    name: "manufacturConfirmapprovalDetails",
    component: () => import("../views/ecManufacturConfirmapproval/manufacturConfirmapprovalDetails"),

  },
  // GSN资格审查审核
  {
    path: "/gsnSupplierApply",
    name: "gsnSupplierApply",
    component: () => import("../views/gsnSupplierApply"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/gsnSupplierApplyDetails",
    name: "gsnSupplierApplyDetails",
    component: () => import("../views/gsnSupplierApply/detail"),
  },
  {
    path: "/gsnSupplierApplySearch",
    name: "gsnSupplierApplySearch",
    component: () => import("../views/gsnSupplierApply/search"),
  },
  // GSN资格审查确认
  {
    path: "/gsnSupplierConfirm",
    name: "gsnSupplierConfirm",
    component: () => import("../views/gsnSupplierConfirm"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/gsnSupplierConfirmDetails",
    name: "gsnSupplierConfirmDetails",
    component: () => import("../views/gsnSupplierConfirm/detail"),
  },
  {
    path: "/gsnSupplierConfirmSearch",
    name: "gsnSupplierConfirmSearch",
    component: () => import("../views/gsnSupplierConfirm/search"),
  },
  // GSN产品目录审查确认
  {
    path: "/gsnProductsConfirm",
    name: "gsnProductsConfirm",
    component: () => import("../views/gsnProductsConfirm"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/gsnProductsConfirmDetails",
    name: "gsnProductsConfirmDetails",
    component: () => import("../views/gsnProductsConfirm/detail"),
  },
  {
    path: "/gsnProductsConfirmSearch",
    name: "gsnProductsConfirmSearch",
    component: () => import("../views/gsnProductsConfirm/search"),
  },
  // GSN产品目录审查审核
  {
    path: "/gsnProductsApply",
    name: "gsnProductsApply",
    component: () => import("../views/gsnProductsApply"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/gsnProductsApplyDetails",
    name: "gsnProductsApplyDetails",
    component: () => import("../views/gsnProductsApply/detail"),
  },
  {
    path: "/gsnProductsApplySearch",
    name: "gsnProductsApplySearch",
    component: () => import("../views/gsnProductsApply/search"),
  },
  // GSN产品目录类型变更确认
  {
    path: "/gsnProductsChangeConfirm",
    name: "gsnProductsChangeConfirm",
    component: () => import("../views/gsnProductsChangeConfirm"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/gsnProductsChangeConfirmDetails",
    name: "gsnProductsChangeConfirmDetails",
    component: () => import("../views/gsnProductsChangeConfirm/detail"),
  },
  {
    path: "/gsnProductsChangeConfirmSearch",
    name: "gsnProductsChangeConfirmSearch",
    component: () => import("../views/gsnProductsChangeConfirm/search"),
  },
  // GSN产品目录类型变更审核
  {
    path: "/gsnProductsChangeApply",
    name: "gsnProductsChangeApply",
    component: () => import("../views/gsnProductsChangeApply"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/gsnProductsChangeApplyDetails",
    name: "gsnProductsChangeApplyDetails",
    component: () => import("../views/gsnProductsChangeApply/detail"),
  },
  {
    path: "/gsnProductsChangeApplySearch",
    name: "gsnProductsChangeApplySearch",
    component: () => import("../views/gsnProductsChangeApply/search"),
  },
  // GSN违约处理提报审核
  {
    path: "/gsnBreachApply",
    name: "gsnBreachApply",
    component: () => import("../views/gsnBreachApply"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/gsnBreachApplyDetails",
    name: "gsnBreachApplyDetails",
    component: () => import("../views/gsnBreachApply/detail"),
  },
  {
    path: "/gsnBreachApplySearch",
    name: "gsnBreachApplySearch",
    component: () => import("../views/gsnBreachApply/search"),
  },
  // GSN风险处理提报审核
  {
    path: "/gsnRiskApply",
    name: "gsnRiskApply",
    component: () => import("../views/gsnRiskApply"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/gsnRiskApplyDetails",
    name: "gsnRiskApplyDetails",
    component: () => import("../views/gsnRiskApply/detail"),
  },
  {
    path: "/gsnRiskApplySearch",
    name: "gsnRiskApplySearch",
    component: () => import("../views/gsnRiskApply/search"),
  },
  // GSN资质证书过期启用审核
  {
    path: "/gsnExpiredCertificate",
    name: "gsnExpiredCertificate",
    component: () => import("../views/gsnExpiredCertificate"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/gsnExpiredCertificateDetails",
    name: "gsnExpiredCertificateDetails",
    component: () => import("../views/gsnExpiredCertificate/detail"),
  },
  {
    path: "/gsnExpiredCertificateSearch",
    name: "gsnExpiredCertificateSearch",
    component: () => import("../views/gsnExpiredCertificate/search"),
  },
  // 1. 二阶段竞争性报价审批
  {
    path: "/ecCompetitiveOfferStageTwo",
    name: "ecCompetitiveOfferStageTwo",
    component: () => import("../views/ecCompetitiveOfferStageTwo"),
    meta: {
      keepAlive: true
    }
  },
  // 二阶段竞争性报价审批 详情页
  {
    path: "/ecCompetitiveOfferStageTwoDetail",
    name: "ecCompetitiveOfferStageTwoDetail",
    component: () => import("../views/ecCompetitiveOfferStageTwo/detail")
  },
  // 二阶段竞争性报价审批 搜索页
  {
    path: "/ecCompetitiveOfferStageTwoSearch",
    name: "ecCompetitiveOfferStageTwoSearch",
    component: () => import("../views/ecCompetitiveOfferStageTwo/search")
  },

  {
    path: "/ecCompetitiveOfferStageTwoMate",
    name: "ecCompetitiveOfferStageTwoMate",
    component: () => import("../views/ecCompetitiveOfferStageTwo/material")
  },
  // 2. 二阶段 招标文件 确认列表
  {
    path: "/ecBiddingDocuments",
    name: "ecBiddingDocuments",
    component: () => import("../views/ecBiddingDocuments")
  },

  // // 二阶段 招标文件 详情页
  // {
  //   path: "/ecBiddingDocumentsDetail",
  //   name: "ecBiddingDocumentsDetail",
  //   component: () => import("../views/ecBiddingDocuments/detail")
  // },

  // 3. 供应商分项报价
  {
    path: "/ecItemizedQuotation",
    name: "ecItemizedQuotation",
    component: () => import("../views/ecItemizedQuotation"),
    meta: {
      keepAlive: true
    }
  },

  // 供应商分项报价 详情
  {
    path: "/ecItemizedQuotationDetail",
    name: "ecItemizedQuotationDetail",
    component: () => import("../views/ecItemizedQuotation/detail")
  },


  // 4. 监造方案
  {
    path: "/ecEngineerScheme",
    name: "ecEngineerScheme",
    component: () => import("../views/ecEngineerScheme")
  },

  {
    path: "/ecEngineerSchemeDetail",
    name: "ecEngineerSchemeDetail",
    component: () => import("../views/ecEngineerScheme/detail")
  },

  // 5. 监造方案变更
  {
    path: "/ecSchemeChange",
    name: "ecSchemeChange",
    component: () => import("../views/ecSchemeChange")
  },
  // 监造方案变更详情
  {
    path: "/ecSchemeChangeDetail",
    name: "ecSchemeChangeDetail",
    component: () => import("../views/ecSchemeChange/detail")
  },
  {
    path: "/list/:pages",
    component: () => import("../views/container/index"),
  },
  {
    path: "/detail/:pages",
    component: () => import("../views/container/detail"),
  },
  {
    path: "/search/:pages",
    component: () => import("../views/container/search"),
  },
  {
    path: "/common/history",
    name: 'savingHistory',
    component: () => import("../views/container/savingHistory"),
  },
  {
    path: "/404",
    name: "notFound",
    component: () => import("../views/404"),
  }, {
    path: "*",
    redirect: "/404"
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
