// NOTE 页面 【页面名称】
export const name = "logistics"; // 页面名称，对应路由
export const pageTitle = "物流方案审批"; // 列表页标题

// NOTE 路由
export const listPage = `/list/${name}`; // 详情页路由
export const detailPage = `/detail/${name}`; // 详情页路由
export const searchPage = `/search/${name}`; // 搜索页路由

// NOTE 接口
export const listApi = "getProcLogisticPlanList"; // 列表页接口
export const detailApi = "proclogisticplan"; // 详情页接口
export const listApproveApi = "auditProcLogisticPlanList"; // 列表页审批接口
export const detailApproveApi = "auditProcLogisticPlan"; // 详情页审批接口

export const searchType = 260; // 搜索路径

export const logisticsAudit = 1; // 物流审核
export const logisticsChange = '0'; // 物流变更
export const isApproveType = 1;

// NOTE 数据格式化 及 参数处理

// 列表卡片 数据处理
export const fillingCard = function (item) {
  item.createUser = item.userName;
  item.supplierName = item.planTopic;
};

// 详情页 数据参数
export const detailParam = function (query) {
  return {
    dproSupeProjectId: query.id,
  };
};

// 详情页 数据处理
export const fillingDetail = function (data) {
  let mapsEnum = {
    10008100: "海运",
    10008110: "铁运",
    10008120: "汽运",
    10008130: "水运",
    10008140: "空运",
  };

  data.transportText = mapsEnum[data.transport]

  data.mateLists = data.procLogisticProject
  data.pageTitle = data.planTopic

  return data;
};

// 列表审批参数
export const listApproveParam = "procLogisticPlanIds";
// 详情页审批参数
export const detailApproveParam = function (query) {
  return {
    procLogisticPlanId: query.id,
  };
};

// NOTE 列表页配置

// 列表卡片 数据
export const formConfig = {
  data: [],
  columns: [
    { prop: "erpLogistic", label: "物流合同号" },
    { prop: "zzhtbh", label: "采购合同号" },
    { prop: "yezhu", label: "业主单位" },
    { prop: "procName", label: "物料描述" },
  ],
  total: 0,
  url: detailPage,
  type: "path",
};

// NOTE 详情页配置
export const showTop = false; // 是否显示top区域

// 头部区域配置
export const basicTop = {
  data: [],
  columns: [],
};

/**
 * @param label 标签页名称
 * @param name vant ui用于遍历标签页，尽量不要重复
 * @param idx 索引
 * @param {Array} info 数据信息
 * @param infos.cards 是否为物料卡片 为true的话lists[0]则为标题
 */

let labelOne = {
  label: "详情页",
  name: "a",
  idx: 1,
  infos: [
    {
      title: "基本信息",
      name: "1",
      lists: [
        { title: "物流方案", value: "planTopic" },
        { title: "物流商", value: "logisticSupplier" },
        { title: "EC物流合同号", value: "wlContractNo" },
        { title: "物流合同号", value: "erpLogistic" },
        { title: "EC采购合同号", value: "contractno" },
        { title: "采购合同号", value: "zzhtbh" },
        { title: "业主联系人", value: "yzName" },
        { title: "业主联系电话", value: "yzTel" },
        { title: "货物编码", value: "prodcode" },
        { title: "货物名称", value: "prodname" },
        { title: "发货地", value: "startport" },
        { title: "到货地", value: "endport" },
        { title: "运输方式", value: "transportText" }, // 这里根据号码进行判断
        // { title: "车牌号", value: "transportNo" }, // 这里根据号码进行判断
        // 以下cell没有数据则隐藏显示
        // { title: "件数", value: "qty" },
        // { title: "重量", value: "measuint" },
        // { title: "起运港", value: "startport" },
        // { title: "到达港", value: "endport" },
        // { title: "目的地", value: "destination" },
        // { title: "价格条款", value: "priceTerms" },
        // { title: "交货日期", value: "diliverydate" },
      ],
    },
    {
      title: "物流方案",
      name: "2",
      cards: true,
      lists: [
        { title: "", value: "name", cardsTitle: true },
        { title: "计划开始日期", value: "PlanBeginTime" },
        { title: "计划交货日期", value: "PlanEndTime" },
        { title: "备注", value: "remark" },
      ],
    },
    {
      title: "联系人信息",
      name: "3",
      lists: [
        { title: "物流负责人", value: "manager" },
        { title: "联系电话", value: "managerTel" },
        { title: "EMAIL", value: "managerEmail" },
        { title: "手机", value: "managerMobile" },
      ],
    },
  ],
};

export const label = labelOne

// 详情页tab 数据格式化
export const geneTabs = function (datas) {
  labelOne.infos[0].lists = [
    { title: "物流方案", value: "planTopic" },
    { title: "物流商", value: "logisticSupplier" },
    { title: "EC物流合同号", value: "wlContractNo" },
    { title: "物流合同号", value: "erpLogistic" },
    { title: "EC采购合同号", value: "contractno" },
    { title: "采购合同号", value: "zzhtbh" },
    { title: "业主联系人", value: "yzName" },
    { title: "业主联系电话", value: "yzTel" },
    { title: "货物编码", value: "prodcode" },
    { title: "货物名称", value: "prodname" },
    { title: "发货地", value: "startport" },
    { title: "到货地", value: "endport" },
    { title: "运输方式", value: "transportText" }, // 这里根据号码进行判断
    // { title: "车牌号", value: "transportNo" }, // 这里根据号码进行判断
  ]

  let custom = new Set()

  console.log('格式化数据', datas.transport)
  // label的映射 枚举
  let mapsEnum = {
    10008100: "船号",
    10008110: "车次号",
    10008120: "车牌号",
    10008130: "船号",
    10008140: "航班号",
  };

  if (datas.transport) {
    let transportN = JSON.stringify({title: mapsEnum[datas.transport],value: "transportNo" })
    custom.add(transportN)
    // labelOne.infos[0].lists.push({
    //   title: mapsEnum[datas.transport],
    //   value: "transportNo",
    // });
  }

  // 展示内容的枚举
  let cellsEnum = {
    qty: "件数",
    measuint: "重量",
    startPlace: "起运港",
    endPlace: "到达港",
    destination: "目的地",
    priceTerms: "价格条款",
    diliverydate: "交货日期",
  };

  let keys = Object.keys(cellsEnum)

  for (let index = 0; index < keys.length; index++) {
    let conditions = datas[keys[index]]
    if (conditions && conditions != '') {
      custom.add(JSON.stringify({ title: cellsEnum[keys[index]], value: keys[index] }))
    }
  }

  let cusObj = [...custom].map(item => {
    return JSON.parse(item)
  })

  labelOne.infos[0].lists.push(...cusObj)

  console.log("改造后的", cusObj);

  return [labelOne]
};
