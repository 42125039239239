import common from "@utils/common";

// NOTE 页面 【合同评分审批】
export const name = "rate"; // 页面名称，对应路由
export const pageTitle = "合同评分审批"; // 列表页标题

// NOTE 路由
export const listPage = `/list/${name}`; // 详情页路由
export const detailPage = `/detail/${name}`; // 详情页路由
export const searchPage = `/search/${name}`; // 搜索页路由

// NOTE 接口
export const listApi = "getContractGradeList"; // 列表页接口
export const detailApi = "getContractGradeDetail"; // 详情页接口
export const listApproveApi = "auditContractGradeList"; // 列表页审批接口
export const detailApproveApi = "auditContractGrade"; // 详情页审批接口

export const searchType = 170; // 搜索路径

// NOTE 数据格式化 及 参数处理

// 列表卡片 数据处理
export const fillingCard = function (item) {
  item.createUser = item.createUserName;
  item.supplierName = item.mainMate;
};

// 详情页 数据参数
export const detailParam = function (query) {
  return {
    contractGradeId: query.contGradeId,
    contractId: query.id,
  };
};

// 详情页 数据处理
export const fillingDetail = function (data) {
  data.signedDateStr = `${common.formatDate(data.signedDate)}`;

  // 页面标题更名
  data.pageTitle = data.mainMate;

  // 物资卡片更名
  data.mateLists = data.stockOrderVOList;
  // 审批记录更名
  data.wfapproveinfos = data.wfapproveinfoVOList;

  return data;
};

// 列表审批参数
export const listApproveParam = "contractIds";
// 详情页审批参数
export const detailApproveParam = function (query) {
  return { contractId: query.id };
};

// NOTE 列表页配置

// 列表卡片 数据
export const formConfig = {
  data: [],
  columns: [
    { prop: "contractNo", label: "合同号" },
    { prop: "supplier", label: "供应商" },
    { prop: "zlpf", label: "质量评分" },
    { prop: "deliveryDateScore", label: "交货期评分" },
    { prop: "xjbpf", label: "性价比评分" },
    { prop: "fwpf", label: "服务评分" },
    { prop: "lyqkpf", label: "履约情况评分" },
  ],
  total: 0,
  url: detailPage,
  type: "path",
};

// NOTE 详情页配置
export const showTop = true; // 是否显示top区域

// 头部区域配置
export const basicTop = {
  data: [],
  columns: [
    {
      prop: "zlpf",
      label: "质量评分",
    },
    {
      prop: "deliveryDateScore",
      label: "交货期评分",
    },
    {
      prop: "xjbpf",
      label: "性价比评分",
    },
    {
      prop: "fwpf",
      label: "服务评分",
    },
  ],
};

let labelOne = {
  label: "合同信息",
  name: "a",
  idx: 1,
  infos: [
    {
      title: "合同信息",
      name: "1",
      lists: [
        { title: "公司名称", value: "corpName" },
        { title: "合同号", value: "contractNo" },
        { title: "供应商名称", value: "supplier" },
        { title: "合同标的物", value: "mainMate" },
        { title: "生效日期", value: "signedDateStr" },
        { title: "总金额（元）", value: "totalall" },
        { title: "项目名称", value: "competitiveName" },
        { title: "经办人", value: "createUser" },
        { title: "经办人部门", value: "createDept" },
        { title: "履约情况评分", value: "lyqkpf" },
      ],
    },
    {
      title: "评分内容",
      name: "2",
      lists: [
        { title: "质量评分", value: "zlpfnr" },
        { title: "交货期评分", value: "deliveryDateScorenr" },
        { title: "性价比评分", value: "xjbpfnr" },
        { title: "服务评分", value: "fwpfnr" },
        { title: "评分意见", value: "strIdea" },
      ],
    },
  ],
};

export const tabs = [labelOne]; // 详情页数据
