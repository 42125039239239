import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible'
import '@/assets/css/reset.css'
import '@/assets/css/vant.css'
import api from './api'
import { autoImportComponents, autoImportConfigModel } from '@/utils/autoImport'
import filters from '@/utils/filters'
import { Toast } from 'vant';
Vue.use(Toast);
Vue.prototype.$bus = new Vue()

Toast.setDefaultOptions({ duration: 800 }); 
autoImportComponents(Vue)
autoImportConfigModel(Vue)
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.use(Vant);
Vue.prototype.$api = api
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
