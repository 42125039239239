<template>
  <div class="box">
    <div class="content agree">
      <slot name="personalization"></slot>
      <div class="examinationtitle">审批意见</div>
      <van-field
        v-model="message"
        rows="5"
        autosize
        type="textarea"
        placeholder="请输入留言"
        show-word-limit
        class="msg-area"
      />
    </div>
    <div class="footer">
      <van-button
        round
        type="info"
        @click="submit"
        class="footerbut"
        v-if="!submitflag"
        >确定</van-button
      >
      <van-button
        round
        type="info"
        @click="submit"
        class="footerbut"
        disabled
        v-else
        >提交中</van-button
      >
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
export default {
  props: {
    submitflag: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3"],
      message: "",
    };
  },
  methods: {
    submit() {
      if (!this.message) {
        Dialog.alert({
          message: "请填写具体不批准原因",
        });
      } else {
        this.$parent.auditApply(2, this.message);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.examinationtitle {
  font-size: 14px;
  line-height: 40px;
  margin-left: 4px;
  color: #8f8f8f;
  font-weight: 500;
}
.msg-area {
  background-color: #fff;
}
/deep/.agree {
  top: 50px !important;
  .van-cell {
    background-color: #fff;
  }
}
</style>
