// import common from "@utils/common";

// NOTE 页面 【页面名称】
export const name = "schemeConfirm"; // 页面名称，对应路由
export const pageTitle = "监造方案确认"; // 列表页标题

// NOTE 路由
export const listPage = `/list/${name}`; // 详情页路由
export const detailPage = `/detail/${name}`; // 详情页路由
export const searchPage = `/search/${name}`; // 搜索页路由

// NOTE 接口
export const listApi = "getProSupeProjectList"; // 列表页接口
export const detailApi = "getProSupeProjectDetail"; // 详情页接口
export const listApproveApi = "auditProcSupeProjectList"; // 列表页审批接口
export const detailApproveApi = "auditProcSupeProject"; // 详情页审批接口

export const searchType = 140; // 搜索路径
export const schemeChange = 2; // 监造变更，变更为1，监造为0

// NOTE 数据格式化 及 参数处理

// 列表卡片 数据处理
export const fillingCard = function (item) {
  item.supplierName = item.planTopic;
};

// 详情页 数据参数
export const detailParam = function (query) {
  return {
    dproSupeProjectId: query.supeplanId,
    isChange: 0,
    changeId: query.changeId,
  };
};

// 详情页 数据处理
export const fillingDetail = function (data) {
  // 页面标题更名
  data.pageTitle = data.planTopic;

  // 审批记录更名
  data.wfapproveinfos = data.wfapproveinfoVOList;
  data.jzwz.map(item => {
    item.prodNumText = `${item.prodNum} ${item.measunit}`
    item.theProdNumText = `${item.theProdNum} ${item.measunit}`
    item.doneProdNumText = `${item.doneProdNum} ${item.measunit}`
  })
  data.mateLists = data.jzwz;

  // 对附件进行分组
  let schemeObj = {};
  let files = data.files || [];

  for (let i = 0; i < files.length; i++) {
    let filecode = files[i].filecode;
    if (schemeObj[filecode]) {
      // 有这个键
      schemeObj[filecode]["files"].push({
        id: files[i].id,
        title: files[i].title,
      });
    } else {
      // 没有这个键
      schemeObj[filecode] = {
        type: files[i].fileCodename,
        files: [{ id: files[i].id, title: files[i].title }],
      };
    }
  }

  data.schemeFiles = Object.values(schemeObj);

  return data;
};

// 列表审批参数
export const listApproveParam = "procSupeProjectIds";
// 详情页审批参数
export const detailApproveParam = function (query) {
  return {
    procSupeProjectId: query.supeplanId,
  };
};

// NOTE 列表页配置

// 列表卡片 数据
export const formConfig = {
  data: [],
  columns: [
    { prop: "supeorderNo", label: "监造合同号" },
    { prop: "manuContractno", label: "采购合同号" },
    { prop: "supeSupplierName", label: "监造商" },
    { prop: "manusuppliername", label: "制造商" },
  ],
  total: 0,
  url: detailPage,
  type: "path",
};

// NOTE 详情页配置
export const showTop = true; // 是否显示top区域

// 头部区域配置
export const basicTop = {
  data: [],
  columns: [
    { prop: "supeContractId", label: "监造合同号" },
    { prop: "manuErpContractId", label: "采购合同号" },
    { prop: "supeSupplierName", label: "监造商" },
    { prop: "manusuppliername", label: "制造厂" },
    { prop: "agreementCode", label: "框架协议编号" },
    { prop: "supeJobno", label: "监造项目编号" },
  ],
};

/**
 * @param label 标签页名称
 * @param name vant ui用于遍历标签页，尽量不要重复
 * @param idx 索引
 * @param {Array} info 数据信息
 * @param infos.cards 是否为物料卡片 为true的话lists[0]则为标题
 */

let labelOne = {
  label: "详情页",
  name: "a",
  idx: 1,
  infos: [
    // 这里在配置文件中  需要将详情页data处理成为mates
    {
      title: "监造物资",
      name: "1",
      cards: true,
      lists: [],
    },
    {
      title: "附件",
      name: "2",
      insert: true,
    },
    {
      title: "联系人信息",
      name: "3",
      lists: [
        { title: "监造工程师", value: "manager" },
        { title: "联系电话", value: "managerTel" },
        { title: "EMAIL", value: "managerMail" },
        { title: "手机", value: "managerMobile" },
      ],
    },
  ],
};

// 详情页tab 数据格式化
export const geneTabs = function (datas) {
  if (datas.isHaveManuPlan == 1) {
    labelOne.infos[0].lists = [
      { title: "", value: "prodName", cardsTitle: true },
      { title: "物资编码", value: "prodCode" },
      { title: "采购数量", value: "prodNumText" },
      { title: "所属制造计划", value: "fromzzjh" },
      { title: "交货日期", value: "needdate" },
      { title: "业主单位", value: "ownerDept" },
      { title: "项目名称", value: "itemName" },
      { title: "备注", value: "note" },
    ];
  } else {
    labelOne.infos[0].lists = [
      { title: "", value: "prodName", cardsTitle: true },
      { title: "物资编码", value: "prodCode" },
      { title: "采购数量", value: "prodNumText" },
      { title: "本次监造数量", value: "theProdNumText" },
      { title: "已监造数量", value: "doneProdNumText" },
      { title: "交货日期", value: "needdate" },
      { title: "业主单位", value: "ownerDept" },
      { title: "项目名称", value: "itemName" },
      { title: "备注", value: "note" },
    ];
  }

  return [labelOne];
};
