export default {
  getQueryString: function (name) {
    const url = window.location.href;
    console.log(url);
    if (url.indexOf("?") !== -1) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      const r = window.location.hash.split("?")[1].match(reg);
      console.log(r);
      if (r != null) {
        return decodeURI(r[2]);
      }
      return undefined;
    }
    return undefined;
  },

  /** 时间处理方法 */
  formatDate(date) {
    // 接收字符串或时间对象
    date = typeof date == "object" ? date : new Date(date);
    let yyyy = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let mm = month > 9 ? month : `0${month}`;
    let dd = day > 9 ? day : `0${day}`;

    return `${yyyy}-${mm}-${dd}`;
  },
};
