<template>
  <div class="task">
    <van-cell-group
      class="group"
      v-for="(item, index) in formData"
      :key="index"
    >
      <van-cell title="审核级别" :value="item.auditlevel" />
      <van-cell title="审核节点名称" :value="item.auditdesc" />
      <van-cell title="审核人" :value="item.auditordesc" />
      <van-cell title="审核时间" :value="formatDate(item.audittime)" />
      <van-cell
        title="审核状态"
        :value="
          item.auditFlag == 0 ? '提交' : item.auditFlag == 1 ? '通过' : '拒绝'
        "
      />
      <van-cell title="审核意见" :value="item.auditopinion" />
    </van-cell-group>
  </div>
</template>
<script>
export default {
  props: {
    applyId: {
      require: true,
      type: String,
      default: '0'
    },
    tableName: {
      type: Number,
      default: 0
    },
    applyType: {
      type: Number,
      default: 0
    }
  },
  name: "task",
  data() {
    return {
      formData: []
    };
  },
  created() {
    this.getList()
  },
  computed: {},
  methods: {
    async getList() {
      let params = {
        applyId: this.applyId,
        tableName: this.tableName
      }
      if (this.applyType != 0) {
        params = {
          ...params,
          applyType: this.applyType
        }
      }
      let data = await this.$api.gsn.getTaskHistroy(params);
      this.formData = data
    },
    formatDate(date) {
      let d = new Date(date)
      const month = d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)
      const day = d.getDate() >= 10 ? d.getDate() : '0' + (d.getDate())
      return `${d.getFullYear()}-${month}-${day}`;
    },
  },
};
</script>
<style lang="less">
.task {
  .group {
    margin-bottom: 10px;
  }
}
</style>