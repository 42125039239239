<template>
  <div class="basic">
    <!--numberrows == 3 一行三个 numberrows == 2 一行两个-->
    <div class="basic-top" v-if="numberrows == 3 || numberrows == 2">
      <div
        :class="
          numberrows == 3 ? 'top-text' : numberrows == 2 ? 'top-texttwo' : ''
        "
        v-for="(item, i) in basicTop.columns"
        :key="i"
      >
        <span class="top-word">{{ item.label }}</span>
        <span class="bottom-word"
          >{{ result[item.prop]
          }}<span v-if="item.unit">{{ result.unit }}</span></span
        >
      </div>
    </div>
    <!-- 上边3个下边2个 -->
    <div class="basic-top" v-else>
      <div
        :class="i == 0 || i == 1 || i == 2 ? 'top-text' : 'top-texttwo'"
        v-for="(item, i) in basicTop.columns"
        :key="i"
      >
        <span class="top-word">{{ item.label }}</span>
        <span class="bottom-word">{{ result[item.prop] }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "list",
  props: {
    basicTop: {
      require: true,
      type: Object,
      default: () => ({}),
      data: {
        required: true,
        type: Array,
        default: () => [],
      },
      columns: {
        required: true,
        type: Array,
        default: () => [],
      },
    },
    numberrows: {
      require: false,
      type: String,
    },
  },
  data() {
    return {
      checked: false,
      result: [],
      // key: value
    };
  },
  computed: {},
  watch: {
    "basicTop.data": {
      handler: function(val) {
        if (val) {
          this.result = this.basicTop.data;
          console.log(val);
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
.basic {
  // min-height: 140px;
  width: 100%;
  background: #fff;
  border-top: 1px solid rgb(238, 234, 234);
  border-bottom: 1px solid rgb(238, 234, 234);
  .basic-top {
    display: flex;
    flex-wrap: wrap;
    margin: 15px;
    .top-text {
      flex-shrink: 0;
      width: 32%;
      min-height: 60px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      .top-word {
        color: #8f8f8f;
        line-height: 20px;
      }
      .bottom-word {
        color: #333;
        line-height: 20px;
        text-align: center;
      }
    }
    .top-texttwo {
      flex-shrink: 0;
      width: 50%;
      min-height: 60px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      .top-word {
        color: #8f8f8f;
        line-height: 20px;
      }
      .bottom-word {
        color: #333;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
</style>
