// NOTE 页面 【页面名称】
export const name = "saving"; // 页面名称，对应路由
export const pageTitle = "框架协议节约率调整审批"; // 列表页标题

// NOTE 路由
export const listPage = `/list/${name}`; // 详情页路由
export const detailPage = `/detail/${name}`; // 详情页路由
export const searchPage = `/search/${name}`; // 搜索页路由

// NOTE 接口
export const listApi = "getSavingRateList"; // 列表页接口
export const detailApi = "getAgreementsavingrateView"; // 详情页接口
export const listApproveApi = "auditAgreementsavingrateList"; // 列表页审批接口
export const detailApproveApi = "auditAgreementsavingrate"; // 详情页审批接口

export const searchType = 180; // 搜索路径

// NOTE 数据格式化 及 参数处理

// 列表卡片 数据处理
export const fillingCard = function (item) {
  item.createUser = item.createUserName;
  item.supplierName = item.approvalname;
};

// 详情页 数据参数 new
export const detailParam = function (query) {
  return {
    agreementsavingrateId: query.id,
  };
};

// 详情页 数据处理 new
export const fillingDetail = function (data) {
  data.mateLists = data.agreemensavingratePartVOList;
  data.preAuditJson = data.agreemensavingratePartVOList.map((item) => {
    return {
      isValid: "1",
      agreementsavingratePartId: item.id,
    };
  });
  data.pageTitle = data.approvalname;

  return data;
};

// 列表审批参数
export const listApproveParam = "agreementJoinIds";
// 详情页审批参数
export const detailApproveParam = function (query, opts) {
  let opt = opts[1];
  let result = opts[2];

  for (let i = 0; i < opt.length; i++) {
    for (let j = 0; j < result.length; j++) {
      if (result[j] == opt[i].agreementsavingratePartId) {
        opt[i].isValid = "0";
      }
    }
  }

  return {
    agreementsavingrateId: query.id,
    agreemensavingrateAuditJson: JSON.stringify(opt),
  };
};

// NOTE 列表页配置

// 列表卡片 数据
export const formConfig = {
  data: [],
  columns: [
    { prop: "direction", label: "签约类型" },
    { prop: "createdate", label: "提报时间" },
    { prop: "effectivedate", label: "生效时间" },
    { prop: "remark", label: "备注" },
  ],
  total: 0,
  url: detailPage,
  type: "path",
};

// NOTE 详情页配置
export const showTop = false; // 是否显示top区域

// 头部区域配置
export const basicTop = {
  data: [],
  columns: [],
};

/**
 * @param label 标签页名称
 * @param name vant ui用于遍历标签页，尽量不要重复
 * @param idx 索引
 * @param {Array} info 数据信息
 */

let labelOne = {
  label: "",
  name: "a",
  idx: 1,
  infos: [
    {
      title: "节约率调整信息",
      name: "1",
      cards: true,
      lists: [
        { title: "", value: "agreementname", cardsTitle: true },
        { title: "框架协议编号", value: "code" },
        { title: "签约供应商", value: "supplierName" },
        { title: "截止日期", value: "endtime" },
        { title: "调整前节约率（%）", value: "saveratioOld" },
        { title: "调整后节约率（%）", value: "saveratio" },
        { title: "生效时间", value: "effectivedate" },
        { title: "备注", value: "remark" },
      ],
    },
  ],
};

export const tabs = [labelOne]; // 详情页数据
