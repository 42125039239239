import { get, post, put, download } from "../axiosConfig";
export default {
  //搜索历史
  appsearchloglist(params) {
    return get("api-ec/appsearchlog/list", params)
  },
  // 删除
  delete(params) {
    return post("api-ec/appsearchlog/delete", params)
  },
  // 公司筛选
  toCompInfo() {
    return post("api-ec/bidManager/toCompInfo")
  },
  // 采购预案
  getList(params) {
    return get("/api-ec/project/list", params)
  },
  // 二阶段竞争性招标
  getCompetitiveOffer(params) {
    return post("api-ec/bidcompetitive/list", params)
  },
  // 二阶段竞争性招标 详情
  getBidcompetitiveView(params) {
    return post('/api-ec/bidcompetitive/getBidcompetitiveView', params)
  },

  // 二阶段 供应商列表
  getSupplierList(params) {
    return post('/api-ec/bidcompetitive/getSupplierList', params)
  },

  // 二阶段审批
  auditBidcompetitive(params) {
    return post('/api-ec/bidcompetitive/auditBidcompetitive', params)
  },

  // 招标文件 列表
  getBidfileList(params) {
    return post('/api-ec/bidfile/list', params)
  },

  // 招标文件确认通过
  bidfileFinish(params) {
    return post('/api-ec/bidfile/bidfileFinish', params)
  },

  // 供应商分项报价列表
  getItemizedQuotationList(params) {
    return post('/api-ec/bidnotify/list', params)
  },

  // 供应商 分项报价详情
  getItemizedQuotationDetail(params) {
    return post('/api-ec/bidnotify/getBidNotifyView', params)
  },

  // 分项报价物资信息
  getQuoteBillPartView(params) {
    return post('/api-ec/bidnotify/getQuoteBillPartView', params)
  },

  // 分项报价审批
  confirmQuotation(params) {
    return post('/api-ec/bidnotify/confirmQuotation', params)
  },

  // NOTE 监造方案

  // 监造方案列表
  getProSupeProjectList(params) {
    return post('/api-ec/proSupeProject/getProSupeProjectList', params)
  },

  // 监造方案详情
  getProSupeProjectDetail(params) {
    return get('/api-ec/proSupeProject/getProSupeProjectDetail', params)
  },

  // 监造方案审批
  auditProcSupeProject(params) {
    return post('/api-ec/proSupeProject/auditProcSupeProject', params)
  },

  // 监造方案批量审批
  auditProcSupeProjectList(params) {
    return post('/api-ec/proSupeProject/auditProcSupeProjectList', params)
  },

  // NOTE 监造方案变更

  // 监造方案变更审批列表
  getProSupeProjectChangeList(params) {
    return post('/api-ec/proSupeProject/getProSupeProjectChangeList', params)
  },

  // 监造方案变更详情
  getProSupeProjectChangeDetail(params) {
    return get('/api-ec/proSupeProject/getProSupeProjectChangeDetail', params)
  },

  // 监造方案变更审批
  auditProcSupeProjectChange(params) {
    return post('/api-ec/proSupeProject/auditProcSupeProjectChange', params)
  },

  // 监造方案变更批量审批
  auditProcSupeProjectChangeList(params) {
    return post('/api-ec/proSupeProject/auditProcSupeProjectChangeList', params)
  },

  // 1. 框架协议变更行项目审批 列表
  getAgreementAddList(params) {
    return get('/api-ec/agreementAdd/getAgreementAddList', params)
  },

  // 1. 框架协议变更行项目审批 页面详情
  getAgreementAddDetail(params) {
    return get('/api-ec/agreementAdd/getAgreementAddDetail', params)
  },

  // 1. 框架协议变更行项目 审批
  auditAgreementAdd(params) {
    return post('/api-ec/agreementAdd/auditAgreementAdd', params)
  },

  // 1. 框架协议变更行项目 批量
  auditAgreementAddList(params) {
    return post('/api-ec/agreementAdd/auditAgreementAddList', params)
  },

  // 2. 合同评分详情 列表
  getContractGradeList(params) {
    return get('/api-ec/contractGrade/getContractGradeList', params)
  },

  // 2. 合同评分详情 页面详情
  getContractGradeDetail(params) {
    return get('/api-ec/contractGrade/getContractGradeDetail', params)
  },

  // 2. 合同评分详情 审批
  auditContractGrade(params) {
    return post('/api-ec/contractGrade/auditContractGrade', params)
  },

  // 2. 合同评分详情 批量审批
  auditContractGradeList(params) {
    return post('/api-ec/contractGrade/auditContractGradeList', params)
  },


  // 3. 化工品自采 列表
  chemicalsApplyApprovalList(params) {
    return get('/api-ec/authorizeproject/chemicalsApplyApprovalList', params)
  },

  // 3. 化工品自采 详情页
  getChemicalsApplyApprovalView(params) {
    return put(`/api-ec/authorizeproject/getChemicalsApplyApprovalView?authorizeprojectId=${params.authorizeprojectId}`)
  },

  // 3. 化工品自采 物资信息
  getChemicalsApplyApprovalGood(params) {
    return put(`/api-ec/authorizeproject/getChemicalsApplyApprovalGood?authorizeprojectId=${params.authorizeprojectId}`)
  },

  // 3. 化工品自采 审批
  auditChemicalsApplyApproval(params) {
    return post('/api-ec/authorizeproject/auditChemicalsApplyApproval', params)
  },

  // 3. 化工品自采 批量审批
  auditChemicalsApplyApprovalList(params) {
    return post('/api-ec/authorizeproject/auditChemicalsApplyApprovalList', params)
  },

  // 4. 框架协议节约率调整 列表
  getSavingRateList(params) {
    return get('/api-ec/agreementsavingrate/list', params)
  },

  // 4. 框架协议节约率调整 详情页
  getAgreementsavingrateView(params) {
    return put(`/api-ec/agreementsavingrate/getAgreementsavingrateView?agreementsavingrateId=${params.agreementsavingrateId}`)
  },

  // 4. 框架协议节约率调整 详情页 历史记录
  getAgreementlistPart(params) {
    return get(`/api-ec/agreementsavingrate/listPart?agreement=${params}`)
  },

  // 4. 节约率 审批
  auditAgreementsavingrate(params) {
    return post('/api-ec/agreementsavingrate/auditAgreementsavingrate', params)
  },

  // 4. 节约率 批量审批
  auditAgreementsavingrateList(params) {
    return post('/api-ec/agreementsavingrate/auditAgreementsavingrateList', params)
  },

  // 物流方案审批列表（审批、变更审批、确认同接口）
  getProcLogisticPlanList(params) {
    return get(`/api-ec/procLogisticPlan/getProcLogisticPlanList`, params)
  },

  // 物流方案详情（审批、变更审批、确认同接口）
  proclogisticplan(params) {
    return get(`/api-ec/procLogisticPlan/getProcLogisticPlanDetail?dproSupeProjectId=${params.dproSupeProjectId}`)
  },

  // 物流方案详情页审批
  auditProcLogisticPlan(params) {
    return post('/api-ec/procLogisticPlan/auditProcLogisticPlan', params)
  },

  // 物流方案批量审批（列表审批）
  auditProcLogisticPlanList(params) {
    return post('/api-ec/procLogisticPlan/auditProcLogisticPlanList', params)
  },


  // 详情
  getProjectView(params) {
    return put(`/api-ec/project/getProjectView?projectId=${params}`)
  },
  // 审批
  auditProject(params) {
    return post(`/api-ec/project/auditProject?audit=${params.audit}&leadexam=${params.leadexam}&proiectId=${params.proiectId}`)
  },
  // 物资信息
  Projectlist(params) {
    return post(`/api-ec/stockorder/list?projectId=${params.projectId}&pageIndex=${params.pageIndex}&pageSize=${params.pageSize}`)
  },
  // 批量审批
  auditProjectList(params) {
    return post(`/api-ec/project/auditProjectList?projectIds=${params}`)
  },
  // 采购方案审批列表
  approvalList(params) {
    return get("/api-ec/stockaffirm/approvalList", params)
  },
  // 详情
  getStockaffirmDetail(params) {
    return get("/api-ec/stockaffirm/getStockaffirmDetail", params)
  },
  // 审批
  auditStockaffirm(params) {
    return put(`/api-ec/stockaffirm/auditStockaffirm?audit=${params.audit}&leadexam=${params.leadexam}&stockaffirmId=${params.stockaffirmId}`)
  },
  auditStockaffirmList(params) {
    return put(`/api-ec/stockaffirm/auditStockaffirmList?stockaffirmIds=${params}`)
  },
  // 招标委托单审批
  selectBidAppList(params) {
    return post("api-ec/bidManager/selectBidAppList", params)
  },
  //详情
  toConsignInfo(params) {
    return post("/api-ec/bidManager/toConsignInfo", params)
  },
  //包内物资详情
  toPackOrderInfo(params) {
    return post("/api-ec/bidManager/toPackOrderInfo", params)
  },
  //单包详情
  toPacksInfo(params) {
    return post(`/api-ec/bidManager/toPacksInfo`, params)
  },
  //审批记录
  toApprovesBidInfo(params) {
    return post("/api-ec/bidManager/toApprovesBidInfo", params)
  },
  doBidApp(params) {
    return post("/api-ec/bidManager/doBidApp", params)
  },
  //联系人
  toContactsInfo(params) {
    return post("/api-ec/bidManager/toContactsInfo", params)
  },
  // 加入执行企业审批
  getAddCorpToAgreementReportPage(params) {
    return post("/api-ec/agreementJoin/getAddCorpToAgreementReportPage", params)
  },
  // 详情
  getAddCorpToAgreementDetail(params) {
    return get("/api-ec/agreementJoin/getAddCorpToAgreementDetail", params)
  },
  // 审批
  auditAgreement(params) {
    return post("/api-ec/agreementJoin/auditAgreement", params)
  },
  // 批量审批
  auditAgreementJoinList(params) {
    return post("/api-ec/agreementJoin/auditAgreementJoinList", params)
  },
  //独家采购
  onlyapprovalList(params) {
    return get("/api-ec/onlypublicitybill/approvalList", params)
  },
  // 详情
  getOnlypublicitybillDetail(params) {
    return get("/api-ec/onlypublicitybill/getOnlypublicitybillDetail", params)
  },
  // 审批
  auditOnlypublicitybill(params) {
    return put(`/api-ec//onlypublicitybill/auditOnlypublicitybill?audit=${params.audit}&leadexam=${params.leadexam}&onlypublicitybillId=${params.onlypublicitybillId}`)
  },
  //批量审批
  auditOnlypublicitybillList(params) {
    return put(`/api-ec/onlypublicitybill/auditOnlypublicitybillList?onlypublicitybillIds=${params}`)
  },
  //框架协议审批
  getAgreementApproveList(params) {
    return get("/api-ec/agreement/getAgreementApproveList", params)
  },
  //详情
  selectAgreementViewInfos(params) {
    return put(`/api-ec/agreement/selectAgreementViewInfos?agreementId=${params}`)
  },
  // 审批
  frameworkAgreementApprove(params) {
    return post("/api-ec/agreement/frameworkAgreementApprove", params)
  },
  // 批量审批
  batchFrameworkAgreementApprove(params) {
    return post("/api-ec/agreement/batchFrameworkAgreementApprove", params)
  },
  //定标结果审批
  selectPackAppList(params) {
    return post("/api-ec/bidManager/selectPackAppList", params)
  },
  //详情
  toPackageInfo(params) {
    return post("/api-ec/bidManager/toPackageInfo", params)
  },
  //投标人
  toPackageSupps(params) {
    return post("/api-ec/bidManager/toPackageSupps", params)
  },
  // 记录
  toApprovesPackInfo(params) {
    return post("/api-ec/bidManager/toApprovesPackInfo", params)
  },
  // 审批
  doPackApp(params) {
    return post("/api-ec/bidManager/doPackApp", params)
  },
  //招标终止
  getBidTerminateList(params) {
    return post("/api-ec/bidTerminate/getBidTerminateList", params)
  },
  //详情
  getBidTerminateDetail(params) {
    return get("/api-ec/bidTerminate/getBidTerminateDetail", params)
  },
  // 审批
  auditBidTerminate(params) {
    return post("/api-ec/bidTerminate/auditBidTerminate", params)
  },
  //批量
  auditBidTerminateList(params) {
    return post("/api-ec/bidTerminate/auditBidTerminateList", params)
  },
  //招标失败
  selectBiddingFailedApproveList(params) {
    return get("/api-ec/bidding/selectBiddingFailedApproveList", params)
  },
  //详情
  selectbiddingFailedViewInfos(params) {
    return put(`/api-ec/bidding/selectbiddingFailedViewInfos?biddingPackageId=${params.biddingPackageId}&bidfailureId=${params.bidfailureId}&type=${params.type}`)
  },
  // 审批
  biddingFailedApprove(params) {
    return post("/api-ec/bidding/biddingFailedApprove", params)
  },
  //批量
  batchBiddingFailedApprove(params) {
    return post("/api-ec/bidding/batchBiddingFailedApprove", params)
  },
  //招标变更
  selectDataModList(params) {
    return post("/api-ec/dataMod/selectDataModList", params)
  },
  //详情
  toDataModInfo(params) {
    return post("/api-ec/dataMod/toDataModInfo", params)
  },
  //审批
  doDataModApp(params) {
    return post("/api-ec/dataMod/doDataModApp", params)
  },
  //重新询价
  reapprovalList(params) {
    return get("/api-ec/reEnquire/approvalList", params)
  },
  //详情
  getReEnquireDetail(params) {
    return get("/api-ec/reEnquire/getReEnquireDetail", params)
  },
  // 审批
  auditReEnquire(params) {
    return put(`/api-ec/reEnquire/auditReEnquire?audit=${params.audit}&leadexam=${params.leadexam}&reEnquireId=${params.reEnquireId}`)
  },
  //批量审批
  auditReEnquireList(params) {
    return put(`/api-ec/reEnquire/auditReEnquireList?reEnquireIds=${params}`)
  },
  //业务回退
  getWfbackList(params) {
    return post("/api-ec/wfback/getWfbackList", params)
  },
  //详情
  getWfbackDetail(params) {
    return get("/api-ec/wfback/getWfbackDetail", params)
  },
  //审批
  auditWfback(params) {
    return post("/api-ec/wfback/auditWfback", params)
  },
  //批量
  auditWfbackList(params) {
    return post("/api-ec/wfback/auditWfbackList", params)
  },
  //委托采购
  getAuthorizeprojectList(params) {
    return post("/api-ec/authorizeproject/getAuthorizeprojectList", params)
  },
  //信息
  listAp(params) {
    return post("/api-ec/stockorder/listAp", params)
  },
  //详情
  getProjectViewAu(params) {
    return put(`/api-ec/authorizeproject/getProjectView?authorizeprojectId=${params}`)
  },
  //审批
  auditAuthorizeproject(params) {
    return post("/api-ec/authorizeproject/auditAuthorizeproject", params)
  },
  //批量
  auditAuthorizeprojectList(params) {
    return post("/api-ec/authorizeproject/auditAuthorizeprojectList", params)
  },
  //附件下载
  bidAttrDownload(params) {
    return download("/api-ec/fileDownload/FileuploadAction!download", params)
  },
  //招标附件下载
  FileuploadAction(params) {
    return download("/api-ec/file/FileuploadAction!download", params)
  },
  //制造计划列表
  getProcmanuplan(params) {
    return get("/api-ec/procmanuplan/procmanuplan", params)
  },
  //制造计划批量审批
  procmanuplanApprovalList(params) {
    return put(`/api-ec/procmanuplan/procmanuplanApprovalList?ids=${params.ids}&type=${params.type}`)
  },
  //制造计划详情页
  procmanuplan(params) {
    return get(`/api-ec/procmanuplan/procmanuplan/${params.id}`, params)
  },
  //制造计划详情
  procmanuplanInfo(params) {
    return get(`/api-ec/procmanuplan/procmanuplanInfo/${params.id}`, params)
  },
  //制造计划审批
  procmanuplanApproval(params) {
    return put(`/api-ec/procmanuplan/procmanuplanApproval/${params.id}?audit=${params.audit}&leadexam=${params.leadexam}&id=${params.id}&changId=${params.changeid}`)
  },
  //制造方案
  procmanuproject(params) {
    return get("/api-ec/procmanuproject/procmanuproject", params)
  },
  //制造方案详情
  procmanuprojectDeal(params) {
    return get(`/api-ec/procmanuproject/procmanuproject/${params.id}`, params)
  },
  //制造方案审批
  procmanuprojectApproval(params) {
    return put(`/api-ec/procmanuproject/procmanuprojectApproval/${params.id}`, params)
  },
  //制造方案审批批量
  procmanuprojectApprovalList(params) {
    return put(`/api-ec/procmanuproject/procmanuprojectApprovalList?ids=${params.ids}`)
  },
};