// import common from "@utils/common";

// NOTE 页面 【页面名称】
export const name = ""; // 页面名称，对应路由
export const pageTitle = ""; // 列表页标题

// NOTE 路由
export const listPage = `/list/${name}`; // 详情页路由
export const detailPage = `/detail/${name}`; // 详情页路由
export const searchPage = `/search/${name}`; // 搜索页路由

// NOTE 接口
export const listApi = ""; // 列表页接口
export const detailApi = ""; // 详情页接口
export const listApproveApi = ""; // 列表页审批接口
export const detailApproveApi = ""; // 详情页审批接口

export const searchType = 180; // 搜索路径

// NOTE 数据格式化 及 参数处理

// 列表卡片 数据处理
// 函数参数为 item
export const fillingCard = function () { };

// 详情页 数据参数
// 函数参数为 query
export const detailParam = function () { };

// 详情页 数据处理
export const fillingDetail = function (data) {
  return data;
};

// 列表审批参数
export const listApproveParam = "";
// 详情页审批参数
// 函数参数为 query和opts
export const detailApproveParam = function () { };

// NOTE 列表页配置

// 列表卡片 数据
export const formConfig = {
  data: [],
  columns: [
    { prop: "", label: "" },
  ],
  total: 0,
  url: detailPage,
  type: "path",
};

// NOTE 详情页配置
export const showTop = false; // 是否显示top区域

// 头部区域配置
export const basicTop = {
  data: [],
  columns: [],
};

/**
 * @param label 标签页名称
 * @param name vant ui用于遍历标签页，尽量不要重复
 * @param idx 索引
 * @param {Array} info 数据信息
 * @param infos.cards 是否为物料卡片 为true的话lists[0]则为标题
 */

let labelOne = {
  label: "",
  name: "a",
  idx: 1,
  infos: [
    {
      title: "节约率调整信息",
      name: "1",
      cards: true,
      lists: [
        { title: "", value: "agreementname", cardsTitle: true },
        { title: "", value: "" },
      ],
    },
    {
      title: "评分内容",
      name: "2",
      lists: [
        { title: "", value: "" },
      ],
    },
  ],
};

export const tabs = [labelOne]; // 详情页数据
