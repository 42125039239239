// import common from "@utils/common";

// NOTE 页面 【页面名称】
export const name = "chemical"; // 页面名称，对应路由
export const pageTitle = "化工品自采申请"; // 列表页标题

// NOTE 路由
export const listPage = `/list/${name}`; // 详情页路由
export const detailPage = `/detail/${name}`; // 详情页路由
export const searchPage = `/search/${name}`; // 搜索页路由

// NOTE 接口
export const listApi = "chemicalsApplyApprovalList"; // 列表页接口
export const detailApi = "getChemicalsApplyApprovalView"; // 详情页接口
export const listApproveApi = "auditChemicalsApplyApprovalList"; // 列表页审批接口
export const detailApproveApi = "auditChemicalsApplyApproval"; // 详情页审批接口
export const detailMateApi = "getChemicalsApplyApprovalGood"; // 物料信息接口

export const searchType = 150; // 搜索路径

// NOTE 数据格式化 及 参数处理

// 列表卡片 数据处理
export const fillingCard = function (item) {
  item.createUser = item.submitUserName;
  item.supplierName = item.authorizeName;
};

// 详情页 数据参数
export const detailParam = function (query) {
  return {
    authorizeprojectId: query.id,
  };
};

// 详情页 数据处理
export const fillingDetail = function (data) {
  // 页面标题更名
  data.pageTitle = data.authorizeName;
  return data;
};

// 列表审批参数
export const listApproveParam = "authorizeprojectIds";
// 详情页审批参数
export const detailApproveParam = function (query, opts) {
  let details = opts[0];
  return {
    authorizeprojectId: query.id,
    stockorderIds: query.stockorderIds,
    type: details.type,
  };
};

// NOTE 列表页配置

// 列表卡片 数据
export const formConfig = {
  data: [],
  columns: [
    { prop: "reason", label: "申请原因" },
    { prop: "goods", label: "物资" },
  ],
  total: 0,
  url: detailPage,
  type: "path",
};

// NOTE 详情页配置
export const showTop = false; // 是否显示top区域

// 头部区域配置
export const basicTop = {
  data: [],
  columns: [],
};

/**
 * @param label 标签页名称
 * @param name vant ui用于遍历标签页，尽量不要重复
 * @param idx 索引
 * @param {Array} info 数据信息
 */

let labelOne = {
  label: "详情页",
  name: "a",
  idx: 1,
  infos: [
    {
      title: "自采申请原因",
      name: "1",
      lists: [{ title: "申请原因", value: "reason" }],
    },
    {
      title: "物资信息",
      name: "2",
      cards: true,
      lists: [
        { title: "", value: "strprodname", cardsTitle: true },
        { title: "物资编码", value: "strprodcode" },
        { title: "采购数量", value: "number" },
        { title: "单位", value: "measunit" },
        { title: "计划交货日期", value: "startdate" },
        { title: "提交日期", value: "subtime" },
        { title: "备注", value: "prodremark" },
      ],
    },
  ],
};

export const tabs = [labelOne]; // 详情页数据
