import common from "@utils/common";

// NOTE 页面 【框架协议变更行项目审批】
export const name = "protocol"; // 页面名称，对应路由
export const pageTitle = "框架协议变更行项目审批"; // 列表页标题

// NOTE 路由
export const listPage = `/list/${name}`; // 详情页路由
export const detailPage = `/detail/${name}`; // 详情页路由
export const searchPage = `/search/${name}`; // 搜索页路由

// NOTE 接口
export const listApi = "getAgreementAddList"; // 列表页接口
export const detailApi = "getAgreementAddDetail"; // 详情页接口
export const listApproveApi = "auditAgreementAddList"; // 列表页审批接口
export const detailApproveApi = "auditAgreementAdd"; // 详情页审批接口

export const searchType = 160; // 搜索路径

// NOTE 数据格式化 及 参数处理

// 列表卡片 数据处理
export const fillingCard = function (item) {
  item.createUser = item.createUserName;
  item.supplierName = item.name;
  item.period = `${common.formatDate(item.startTime)} 至 ${common.formatDate(
    item.endTime
  )}`;
};

// 详情页 数据参数
export const detailParam = function (query) {
  return {
    agreementAddId: query.id,
    agreementId: query.agreementId,
  };
};

// 详情页 数据处理
export const fillingDetail = function (data) {
  data.dateInterval = `${common.formatDate(
    data.startTime
  )} 至 ${common.formatDate(data.endTime)}`;
  data.signedDateF = `${common.formatDate(data.signedDate)}`;

  // 物资卡片更名
  data.mateLists = data.stockOrderVOList;
  // 审批记录更名
  data.wfapproveinfos = data.wfapproveinfoVOList;
  // 页面标题更名
  data.pageTitle = data.name;

  return data;
};

// 列表审批参数
export const listApproveParam = "agreementAddIds";
// 详情页审批参数
export const detailApproveParam = function (query) {
  return { agreementAddId: query.id };
};

// NOTE 列表页配置

// 列表卡片 数据
export const formConfig = {
  data: [],
  columns: [
    { prop: "supplier", label: "签约供应商" },
    { prop: "code", label: "协议编号" },
    { prop: "period", label: "有效期" },
    { prop: "typename", label: "协议类型" },
    { prop: "type", label: "行项目变更类型" },
  ],
  total: 0,
  url: detailPage,
  type: "path",
};

// NOTE 详情页配置

export const showTop = true; // 是否显示top区域

// 头部区域配置
export const basicTop = {
  data: [],
  columns: [
    { prop: "supplier", label: "签约供应商" },
    { prop: "code", label: "协议编号" },
    { prop: "dateInterval", label: "有效期" },
    { prop: "changeType", label: "行项目变更类型" },
  ],
};

/**
 * @param label 标签页名称
 * @param name vant ui用于遍历标签页，尽量不要重复
 * @param idx 索引
 * @param {Array} info 数据信息
 */

// 变更信息
let labelOne = {
  label: "变更信息",
  name: "a",
  idx: 1,
  infos: [
    {
      title: "物资信息",
      name: "10",
      cards: true,
      lists: [
        { title: "", value: "productdesc", cardsTitle: true },
        { title: "行项目号", value: "projectNo" },
        { title: "物资编码", value: "strprodcode" },
        { title: "数量", value: "quantity" },
        { title: "单位", value: "unit" },
        { title: "单价", value: "price" },
      ],
    },
    {
      title: "申请变更行项目理由",
      name: "5",
      lists: [{ title: "申请理由", value: "reason" }],
    },
    {
      title: "申请人",
      name: "5",
      lists: [{ title: "申请人", value: "createUserName" }],
    },
  ],
};

// 框架协议信息
let labelTwo = {
  label: "框架协议信息",
  name: "b",
  idx: 2,
  infos: [
    {
      title: "框架协议信息",
      name: "5",
      lists: [
        { title: "协议类型", value: "type" },
        { title: "签约日期", value: "signedDateF" },
        { title: "币种", value: "currency" },
        { title: "节约率", value: "saveratio" },
        { title: "税率", value: "taxcode" },
        { title: "协议目标值（元）", value: "targetvalue" },
        { title: "采购方式", value: "stocktype" },
        { title: "定价机制", value: "pricingtype" },
        { title: "供应商份额分配原则", value: "supplierAsign" },
      ],
    },
  ],
};

export const tabs = [labelOne, labelTwo]; // 详情页数据
