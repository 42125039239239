import { get} from "../axiosConfig";
export default {
  // 登录
  getLoginToken(params) {
    return get("/api/permit/security/getLoginToken", params);
  },
  getUserByToken(params) {
    return get("/api-user/user/getUserByToken",params)
  },
};
