import { get, gpost } from "../axiosConfig";
export default {
  // 单位权限获取（编制单位）
  getUserDep() {
    return get(`/api-gsn/UserAction!listCurrentUserDepPermission.do?flagDep=1`);
  },
  // 资格审查列表页数据 待审批
  getListAllSupplierApply(params) {
    return gpost("/api-gsn/SupplierApplyAction!listAllSupplierApply", params);
  },
  // 资格审查列表页数据 已审批
  getListAllSupplierQuery(params) {
    return gpost("/api-gsn/CatalogQueryAction!listQualificationQuery", params);
  },
  // 审查审核详情
  getSupplierApplyInfo(params) {
    return get(`/api-gsn/SupplierApplyAction!supplierApplyInfo.do`, params);
  },
  // 分类切换
  getListMenu(params) {
    return get(`/api-gsn/RoleAction!listMenuClassification`, params);
  },
  // 审查审核请求
  auditApply(params) {
    return gpost(`/api-gsn/SupplierApplyAuditingAction!auditApply`, params);
  },
  // 批量同意审查审核
  moreAuditApply(params) {
    return gpost(
      `/api-gsn/SupplierApplyAuditingAction!auditApplyList?applyIds=${params}`
    );
  },
  // 审核节点信息
  getTaskList(params) {
    return get(`/api-gsn/WorkflowAction!getWorkflowTaskListById.do?`, params);
  },
  // 审核历史信息
  getTaskHistroy(params) {
    return get(`/api-gsn/WorkflowAction!getAuditedTasks.do`, params);
  },
  // 风险处理提报审核(待审核)
  getRiskApplyList(params) {
    return gpost(`/api-gsn/AssessmentApplyAuditAction!listRiskApply`, params);
  },
  // 风险处理提报审核(已审核)
  getRiskQueryList(params) {
    return gpost(
      `/api-gsn/BreachApplyQueryAction!breachApplySupplierQuery`,
      params
    );
  },
  // 风险表单详情
  getRiskApplyInfo(params) {
    return get(`/api-gsn/AssessmentApplyAuditAction!getRiskAuditInfo`, params);
  },
  // 风险表单审核批量
  auditRiskApplyInfoAuditApplyList(params) {
    return gpost(
      `/api-gsn/AssessmentApplyAuditAction!auditRiskApplyInfoAuditApplyList?applyIds=${params}`
    );
  },
  // 违约处理待审核列表
  getBreachApplyList(params) {
    return gpost(`/api-gsn/AssessmentApplyAuditAction!listBreachApply`, params);
  },
  // 违约处理已审核列表
  getBreachQueryList(params) {
    return gpost(`/api-gsn/BreachApplyQueryAction!breachApplyQuery`, params);
  },
  // 违约处理表单详情
  getBreachAuditInfo(params) {
    return get(
      `/api-gsn/AssessmentApplyAuditAction!getBreachAuditInfo`,
      params
    );
  },
  // 违约处理批量审核请求
  auditZeroBreachApplys(params) {
    return gpost(
      `/api-gsn/AssessmentApplyAuditAction!auditZeroBreachApplys?applyIds=${params}`
    );
  },
  // 违约处理审核请求
  auditAssessmentApply(params) {
    return gpost(
      `/api-gsn/AssessmentApplyAuditAction!auditAssessmentApply`,
      params
    );
  },
  // 过期启用审核待审核列表
  listCertificateExpiredEnable(params) {
    return gpost(
      `/api-gsn/CertificateExpiredEnableAction!listCertificateExpiredEnable`,
      params
    );
  },
  // 过期启用审核已审核列表
  pastDueStart(params) {
    return gpost(`/api-gsn/IntegratedQueryAction!pastDueStart`, params);
  },
  // 过期启用审核详情
  getCertificateExpiredEnableDetail(params) {
    return get(
      `/api-gsn/CertificateExpiredEnableAction!getCertificateExpiredEnableDetail`,
      params
    );
  },
  // 过期启用审核请求
  auditCertificateExpiredEnable(params) {
    return gpost(
      `/api-gsn/CertificateExpiredEnableAction!auditCertificateExpiredEnable`,
      params
    );
  },
  // 过期启用审核批量
  auditCertificateExpiredEnableList(params) {
    return gpost(
      `/api-gsn/CertificateExpiredEnableAction!auditCertificateExpiredEnableList?applyIds=${params}`
    );
  },
};
