<template>
  <div id="app">
    <!-- <router-view></router-view> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
// import axios from "axios";
import common from "@utils/common";
export default {
  data() {
    return {
      token: "",
    };
  },
  created() {
    this.token = common.getQueryString("token");
    if (this.token) {
      this.$store.commit("setToken", this.token);
    }
    // this.getUserByToken()
  },
  methods: {
    //  async getUserByToken() {
    //   const params = {
    //   token:this.token
    //   }
    //   let data = await this.$api.common.getUserByToken(params)
    //   if(data){
    //     this.$store.commit("setToken",this.token);
    //     this.$store.commit("setUesrMessage",data);
    //   }else{
    //     Dialog.confirm({
    //           message: "登录失败请重新登录",
    //         })
    //           .then(() => {
    //             window.history.go(-1);
    //           })
    //           .catch(() => {
    //             window.history.go(-1);
    //           });
    //   }
    // }
  },
};
</script>
<style lang="less">
#app {
  background-color: #f3f5fa;
  min-height: 100%;
  font-size: 12px;
  .box {
    display: flex;
    flex-direction: column;
    .header {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
      .header-search {
        height: 44px;
        font-size: 18px;
        color: #f9f9fb;
        text-align: center;
        line-height: 44px;
        .van-nav-bar {
          background: linear-gradient(to right, #5ca7f0, #2f6edd);
          .van-nav-bar__content {
            .van-nav-bar__title {
              font-size: 0.4rem;
              font-family: medium;
              font-weight: 400;
            }
          }
        }
      }
      .choose {
        min-height: 1.17333rem;
        .choose-div {
          display: flex;
          justify-content: space-around;

          height: 1.17333rem;
          line-height: 0.53333rem;
          background: #fff;
          align-items: center;
          color: #8f8f8f;
          font-size: 12px;
          // .choosetext{
          //   display: flex;
          //   align-items: center;
          //   justify-content:center
          // }
        }
      }
    }
    .contentbox {
      top: 46px !important;
    }
    .content {
      background-color: #f3f5fa;
      position: relative;
      top: 92px;
      // margin-top:92px;
      width: 100%;
      min-height: 400px;
      padding-bottom: 1.35rem;
      .contentsorting {
        min-height: 460px;
        background: #fff;
        .van-tabs {
          .van-tabs__wrap {
            border-bottom: 1px solid rgb(255, 248, 248);
          }
        }
        .sorting {
          padding: 0.3rem;
          .sortingtit {
            margin-top: 10px;
            margin-bottom: 10px;
            color: #333;
            font-weight: 500;
            // font-size: 12px;
          }
          .dissolution {
            min-height: 80px;
            .people {
              float: left;
              width: 60px;
              height: 80px;
              // background: deeppink;
              margin-left: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // flex-shrink:0;
              position: relative;
              .contacti {
                position: absolute;
                top: 10px;
                right: 0;
                color: red;
              }
            }
          }
          .sortingbut {
            // background: chocolate;
            padding-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            .van-button--small {
              padding: 0 1.7rem;
            }
          }
          .reverse {
            font-size: 12px;
            color: #333;
            border: 1px solid rgb(245, 239, 239);
            line-height: 40px;
            padding: 0 0.3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      .examinationtitle {
        font-size: 14px;
        line-height: 40px;
        margin-left: 4px;
        color: #8f8f8f;
        font-weight: 500;
      }
    }
    .footerindex {
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #2d8aff;
      height: 1.35rem;
      background: #f3f5fa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem;
      // padding: 0.3rem;
      .van-button--small {
        width: 1.6rem;
        height: 0.72rem;
        border-radius: 0.08rem;
      }
    }
    .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #2d8aff;
      height: 1.35rem;
      background: #f3f5fa;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .left {
        line-height: 1.35rem;
        width: 50%;
        text-align: center;
        font-size: 14px;
        color: #2d8aff;
      }
      .right {
        font-size: 14px;
        line-height: 1.35rem;
        width: 50%;
        color: #fff;
        text-align: center;
        background: #2d8aff;
      }
      .van-button--normal {
        padding: 0.08rem 4rem;
      }
    }
  }
}
.issfxed {
  border: 1px solid #9cdeec !important;
  .van-button__content {
    .van-button__text {
      color: #9cdeec;
    }
  }
}
.supplies {
  margin-top: 10px;
  // min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;

  .supplies-title {
    font-weight: 500;
    line-height: 25px;
    font-size: 13px;
    color: #312f2f !important;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.disappear {
  .van-tabs__wrap {
    height: 0px !important;
  }
}
@font-face {
  font-family: "iconfont"; /* Project id 2792967 */
  src: url("//at.alicdn.com/t/font_2792967_7dejnr6v63s.woff2?t=1630915077358")
      format("woff2"),
    url("//at.alicdn.com/t/font_2792967_7dejnr6v63s.woff?t=1630915077358")
      format("woff"),
    url("//at.alicdn.com/t/font_2792967_7dejnr6v63s.ttf?t=1630915077358")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shanchu:before {
  content: "\e629";
}
</style>
